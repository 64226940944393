<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="formAction === 'select'">
      <trunk-info
        :trunk="contentFormInfo"
        :additional-validate-route="additionalValidateRoute"
        @click="handleEditBtnClick"
        @dltClick="handleDeleteBtnClick"
      >
      </trunk-info>
    </div>

    <el-dialog
      :visible.sync="openModal"
      fullscreen
      v-if="openModal"
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <page-header :title="__('Trunk')" :contentId="id" />
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-tabs v-model="activeName">
                  <el-tab-pane :label="__('Setting')" name="setting"
                    ><el-form-item :label="__('Name')" prop="trunk_name">
                      <el-input
                        v-model="contentForm.trunk_name"
                        v-loading="isChecking"
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="__('Sip Domain')" prop="sip_domain">
                      <el-input v-model="contentForm.sip_domain"></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('Registration Type')"
                      prop="registration_type"
                    >
                      <el-radio-group
                        v-model="contentForm.registration_type"
                        class="display-block"
                      >
                        <el-radio label="dynamic">{{ __("dynamic") }}</el-radio>
                        <el-radio label="static">{{ __("static") }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-tab-pane>
                  <el-tab-pane :label="__('Primary')" name="primary"
                    ><el-form-item
                      :label="__('Register User Name')"
                      prop="primary_registrant.register_user_name"
                    >
                      <el-input
                        v-model="
                          contentForm.primary_registrant.register_user_name
                        "
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('Override pilot?')"
                      prop="primary_registrant.is_pilot_number_overridden"
                    >
                      <el-radio-group
                        v-model="
                          contentForm.primary_registrant
                            .is_pilot_number_overridden
                        "
                        class="display-block"
                      >
                        <el-radio :label="true">{{ __("yes") }}</el-radio>
                        <el-radio :label="false">{{ __("no") }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item
                      :label="__('Overridden Pilot Number')"
                      prop="primary_registrant.overridden_pilot_number"
                    >
                      <el-input
                        v-model="
                          contentForm.primary_registrant.overridden_pilot_number
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('Outbound Proxy')"
                      prop="primary_registrant.outbound_proxy_ip_port"
                    >
                      <el-input
                        v-model="
                          contentForm.primary_registrant.outbound_proxy_ip_port
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('SBC')"
                      prop="primary_registrant.sbc_internal_ip_port"
                    >
                      <el-input
                        :placeholder="SBC_PCI_PRIMARY_INTERNAL_IP_PORT"
                        v-model="
                          contentForm.primary_registrant.sbc_internal_ip_port
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('External SBC')"
                      prop="primary_registrant.sbc_external_ip_port"
                    >
                      <el-input
                        :placeholder="SBC_PCI_PRIMARY_EXTERNAL_IP_PORT"
                        v-model="
                          contentForm.primary_registrant.sbc_external_ip_port
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('Authentication Username')"
                      :hidden="contentForm.registration_type === 'static'"
                      prop="primary_registrant.dynamic_registrant_user_name"
                      :required="contentForm.registration_type === 'dynamic'"
                    >
                      <el-input
                        v-model="
                          contentForm.primary_registrant
                            .dynamic_registrant_user_name
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('Authentication Password')"
                      :hidden="contentForm.registration_type === 'static'"
                      prop="primary_registrant.dynamic_registrant_user_password"
                      :required="contentForm.registration_type === 'dynamic'"
                    >
                      <el-input
                        v-model="
                          contentForm.primary_registrant
                            .dynamic_registrant_user_password
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('IP Addresses to Whitelist')"
                      prop="primary_registrant.ip_whitelist"
                    >
                      <el-input
                        v-model="contentForm.primary_registrant.ip_whitelist"
                      ></el-input> </el-form-item
                  ></el-tab-pane>
                  <el-tab-pane :label="__('Secondary')" name="secondary"
                    ><el-form-item :label="__('Register User Name')">
                      <el-input
                        v-model="
                          contentForm.secondary_registrant.register_user_name
                        "
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="__('Override pilot?')">
                      <el-radio-group
                        v-model="
                          contentForm.secondary_registrant
                            .is_pilot_number_overridden
                        "
                        class="display-block"
                      >
                        <el-radio :label="true">{{ __("yes") }}</el-radio>
                        <el-radio :label="false">{{ __("no") }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="__('Overridden Pilot Number')">
                      <el-input
                        v-model="
                          contentForm.secondary_registrant
                            .overridden_pilot_number
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="__('Outbound Proxy')">
                      <el-input
                        v-model="
                          contentForm.secondary_registrant
                            .outbound_proxy_ip_port
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="__('SBC')">
                      <el-input
                        :placeholder="SBC_PCI_SECONDARY_INTERNAL_IP_PORT"
                        v-model="
                          contentForm.secondary_registrant.sbc_internal_ip_port
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="__('External SBC')">
                      <el-input
                        :placeholder="SBC_PCI_SECONDARY_EXTERNAL_IP_PORT"
                        v-model="
                          contentForm.secondary_registrant.sbc_external_ip_port
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('Authentication Username')"
                      :hidden="contentForm.registration_type === 'static'"
                    >
                      <el-input
                        v-model="
                          contentForm.secondary_registrant
                            .dynamic_registrant_user_name
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('Authentication Password')"
                      :hidden="contentForm.registration_type === 'static'"
                    >
                      <el-input
                        v-model="
                          contentForm.secondary_registrant
                            .dynamic_registrant_user_password
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="__('IP Addresses to Whitelist')">
                      <el-input
                        v-model="contentForm.secondary_registrant.ip_whitelist"
                      ></el-input>
                    </el-form-item>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <save-button
                  :disabled="!this.canWrite(additionalValidateRoute)"
                  :loading="isLoading"
                  type="primary"
                  @click="createOrEditTrunk"
                  class="submitBtn"
                  :primaryKey="id"
                />
                <el-button @click="handleCancelBtn" class="cancelBtn">{{
                  __("Cancel")
                }}</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import TrunkInfo from "@/views/manage/telephony/trunks/component/TrunkInfo.vue";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { EventBus } from "@/EventBus";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { checkUniqueName } from "@/api/trunks";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    TrunkInfo,
    PageHeader,
    SaveButton
  },
  data() {
    let validateUsername = async (rule, value, callback) => {
      if (this.contentForm.registration_type === "dynamic" && value === "") {
        callback(__("user name is required for dynamic registration"));
      } else {
        callback();
      }
    };
    let validatePassword = async (rule, value, callback) => {
      if (this.contentForm.registration_type === "dynamic" && value === "") {
        callback(__("password is required for dynamic registration"));
      } else {
        callback();
      }
    };
    let validateTrunkName = async (rule, value, callback) => {
      try {
        if (value === null || value === "") {
          callback(new Error(__("trunk name is required")));
        } else {
          if (this.id === 0 && value !== this.contentFormInfo.trunk_name) {
            this.isChecking = true;
            const res = await checkUniqueName(value);
            this.isChecking = false;
            if (res.data.found) {
              callback(new Error(__("trunk name already exists")));
            } else {
              callback();
            }
          }
          if (this.id === -1) {
            this.isChecking = true;
            const res = await checkUniqueName(value);
            this.isChecking = false;
            if (res.data.found) {
              callback(new Error(__("trunk name already exists")));
            } else {
              callback();
            }
          }
        }
      } catch (e) {
        this.isChecking = false;
      }
    };
    let validateIpWhitelist = async (rule, value, callback) => {
      if (value.trim() !== "") {
        const ip_list = value.split(",");
        const ip_regex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
        // Check each IP individually
        for (const ip of ip_list) {
          // Check if the current IP string is a valid IP address
          if (!ip_regex.test(ip.trim())) {
            callback(
              __("must be a comma separated list of valid ip addresses")
            );
          }
        }
      } else {
        callback();
      }
    };
    return {
      activeName: "setting",
      isReceiving: false,
      isChecking: false,
      rules: {
        trunk_name: [
          { required: true, trigger: "blur", validator: validateTrunkName }
        ],
        sip_domain: [
          {
            required: true,
            trigger: "blur",
            message: __("Sip Domain is required")
          }
        ],
        registration_type: [
          {
            required: true,
            trigger: "blur",
            message: __("Registration Type is required")
          }
        ],
        primary_registrant: {
          register_user_name: {
            required: true,
            trigger: "blur",
            message: __("register username is required")
          },
          is_pilot_number_overridden: {
            required: true,
            trigger: "blur",
            message: __("is pilot number override is required")
          },
          overridden_pilot_number: {
            required: false
          },
          outbound_proxy_ip_port: {
            required: true,
            trigger: "blur",
            message: __("outbound proxy is required")
          },
          sbc_internal_ip_port: { required: false },
          sbc_external_ip_port: { required: false },
          ip_whitelist: {
            required: false,
            trigger: "blur",
            validator: validateIpWhitelist
          },
          dynamic_registrant_user_name: {
            validator: validateUsername,
            trigger: "blur"
          },
          dynamic_registrant_user_password: {
            validator: validatePassword,
            trigger: "blur"
          }
        },
        secondary_registrant: {
          register_user_name: {
            required: false
          },
          is_pilot_number_overridden: {
            required: false
          },
          overridden_pilot_number: {
            required: false
          },
          outbound_proxy_ip_port: {
            required: false
          },
          sbc_internal_ip_port: { required: false },
          sbc_external_ip_port: { required: false },
          ip_whitelist: {
            required: false,
            trigger: "blur",
            validator: validateIpWhitelist
          },
          dynamic_registrant_user_name: {
            required: false
          },
          dynamic_registrant_user_password: {
            required: false
          }
        }
      },
      registration_form_title: "",
      registration_form: {
        registrant_type: "",
        register_user_name: "",
        is_pilot_number_overridden: "",
        overridden_pilot_number: "",
        outbound_proxy_ip_port: "",
        sbc_internal_ip_port: "",
        sbc_external_ip_port: "",
        ip_whitelist: "",
        dynamic_registrant_user_name: "",
        dynamic_registrant_user_password: ""
      },
      primary_registration_form: {
        registrant_type: "",
        register_user_name: "",
        is_pilot_number_overridden: "",
        overridden_pilot_number: "",
        outbound_proxy_ip_port: "",
        sbc_internal_ip_port: "",
        sbc_external_ip_port: "",
        ip_whitelist: "",
        dynamic_registrant_user_name: "",
        dynamic_registrant_user_password: ""
      },
      secondary_registration_form: {
        registrant_type: "",
        register_user_name: "",
        is_pilot_number_overridden: "",
        overridden_pilot_number: "",
        outbound_proxy_ip_port: "",
        sbc_internal_ip_port: "",
        sbc_external_ip_port: "",
        ip_whitelist: ""
      },
      trunkForm: {},
      additionalValidateRoute: "trunks",
      trunkFormKey: 0,
      isSubmitting: false,
      formLabelWidth: "80px",
      timer: null,
      dialog: false,
      tabs: {
        setting: ["trunk_name", "sip_domain", "registration_type"],
        primary: [
          "register_user_name",
          "is_pilot_number_overridden",
          "outbound_proxy_ip_port",
          "dynamic_registrant_user_name",
          "dynamic_registrant_user_password"
        ]
      }
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    ...mapState("trunks", {
      isLoading: state => state.isLoading,
      SBC_PCI_PRIMARY_INTERNAL_IP_PORT: state =>
        state.SBC_PCI_PRIMARY_INTERNAL_IP_PORT,
      SBC_PCI_SECONDARY_INTERNAL_IP_PORT: state =>
        state.SBC_PCI_SECONDARY_INTERNAL_IP_PORT,
      SBC_PCI_PRIMARY_EXTERNAL_IP_PORT: state =>
        state.SBC_PCI_PRIMARY_EXTERNAL_IP_PORT,
      SBC_PCI_SECONDARY_EXTERNAL_IP_PORT: state =>
        state.SBC_PCI_SECONDARY_EXTERNAL_IP_PORT
    })
  },

  async mounted() {
    this.trunkForm = _.cloneDeep(this.trunk);
  },

  async created() {},

  methods: {
    ...mapActions("trunks", {
      createTrunk: "createTrunk",
      updateTrunk: "updateTrunk",
      registerTrunk: "registerTrunk",
      deleteContentMethod: "deleteTrunk",
      undoDeleteContent: "undoDeleteTrunk"
    }),
    createOrEditTrunk() {
      this.$refs.contentForm.validate((valid, errors) => {
        if (valid) {
          this.isSubmitting = true;
          const process = this.id === -1 ? this.createTrunk : this.updateTrunk;
          this.contentForm.sp_id = this.selectedServiceProviderId;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Trunk added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Trunk updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          this.$message({
            type: "error",
            message: __("Please add required values before saving")
          });

          let errorPropName = Object.keys(errors);
          console.log(errorPropName);

          let settingTab = _.findKey(this.tabs, function(structure) {
            return structure.some(r => errorPropName.includes(r));
          });

          if (!_.isEmpty(settingTab)) {
            this.activeName = settingTab;
          } else {
            this.activeName = "primary";
          }
          return false;
        }
      });
    },
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.activeName = "setting";
      this.handleCancel();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleDeleteBtnClick() {
      this.handleDelete(this.contentForm);
    }
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/node_common.scss";
::v-deep .trunk-form-dialog {
  max-height: 90vh !important;
}
::v-deep .trunk-edit-tab {
  min-width: 600px !important;
  max-height: 70vh !important;
}
.tabClass {
  ::v-deep .el-tabs__body {
    min-width: 600px !important;
    max-height: 70vh !important;
  }
  ::v-deep .el-tabs__header {
    padding-left: 25%;
    padding-right: 25%;
    position: relative;
    margin: 0 0 15px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: $content-theme-color;
  }
  ::v-deep .el-tabs__active-bar {
    background-color: $content-theme-color;
  }
  ::v-deep .el-tabs__item:hover {
    color: $content-theme-hover-color;
    cursor: pointer;
  }
}
</style>
